<script setup lang="ts">
import { AsfTransitions, AsfVariationColors, AsfVariationSwatchProps } from '@ui/types'
import { AsfLink } from '#components'

const props = withDefaults(defineProps<AsfVariationSwatchProps>(), {
  role: 'checkbox',
  selected: false,
  disabled: false,
  hasOption: false,
  optionCount: 0,
  isAlwaysInteractive: false,
  noSwatchesHighlighter: false
})
const emit = defineEmits(['swatch:click'])

const { t, te, fallbackLocale } = useI18n()
const swatchName = computed(() => {
  if (
    te('search.facets.sizeLabels.' + props.value) ||
    te('search.facets.sizeLabels.' + props.value, fallbackLocale.value as string)
  ) {
    return t('search.facets.sizeLabels.' + props.value)
  } else {
    return props.name
  }
})
const { sizeProps } = useSize({}, ['16', '12'])
const transition = ref<AsfTransitions>('asf-bounce')
const modifier = computed(() => `m-${props.type}`)

const isColor = (str?: string): str is keyof typeof AsfVariationColors => {
  if (str) {
    return str in AsfVariationColors
  } else {
    return false
  }
}
const color = computed(() => {
  if (props.type === 'color') {
    const colorValue = (props.valueAttr || props.value || '').toUpperCase()

    if (isColor(colorValue)) {
      return AsfVariationColors[colorValue]
    }
  }

  return ''
})

const handleSwatchClick = () => {
  emit('swatch:click', { name: props.name, value: props.value })
  EventBus.emit('slider:update-slider')
}
</script>
<template>
  <component
    :is="link ? AsfLink : 'button'"
    :id="`refinement-${productId}-${type}-${value.toLowerCase()}`"
    v-e2e="`${value}-swatch`"
    class="asf-variation-swatch"
    :link="link ? link : undefined"
    :type="link ? undefined : 'button'"
    :class="[modifier, { 'is-selected': selected && !noSwatchesHighlighter, 'is-disabled': disabled }]"
    :role="role"
    :aria-checked="selected?.toString()"
    :aria-disabled="String(!isAlwaysInteractive && disabled)"
    v-bind="$attrs"
    :disabled="!isAlwaysInteractive && disabled"
    @click="handleSwatchClick"
  >
    <transition :name="transition">
      <span class="asf-variation-swatch__container">
        <span class="asf-variation-swatch__inner">
          <AsfColor v-if="color" :color="color" size="10" class="asf-variation-swatch__name" />
          <span v-else class="asf-variation-swatch__name" :style="sizeProps">
            <span>{{ swatchName }}</span>
          </span>
          <span class="asf-variation-swatch__overlay" />
        </span>
      </span>
    </transition>
    <span v-if="!hasOption" class="asf-variation-swatch__value">{{ value }}</span>
    <span v-else class="asf-variation-swatch__option">
      <span v-if="color" class="asf-variation-swatch__option-label">{{ name }}</span>
      <span v-e2e="'variation-swatch-count'" class="asf-variation-swatch__option_count">({{ optionCount }})</span>
    </span>
  </component>
</template>
<style lang="postcss">
@import '@components/atoms/VariationSwatch/VariationSwatch.css';
</style>
