import { AgnosticBaseFacetAttrs, AgnosticEditableProduct, AgnosticProduct } from 'shared/types'
import { AsfVariationAttribute, AsfVariationOptionsProps, AsfVariationSwatchType } from '@ui/types'

type VariantFilters = { [key: string]: string }
const variationSwatchTypes: Record<AsfVariationSwatchType, AsfVariationSwatchType> = {
  brand: 'brand',
  color: 'color',
  size: 'size'
}
const variationSwatchTypeValues = invertObject(variationSwatchTypes)

function isVariatyonType(str: string): str is AsfVariationOptionsProps['type'] {
  return str in variationSwatchTypeValues
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useVariationModel(
  product: Ref<AgnosticProduct | AgnosticEditableProduct>,
  initialFilters?: Partial<VariantFilters>
) {
  const { send } = useUiNotification()
  const { t } = useI18n()

  const masterVariantAttributes = computed(
    () => ('variations' in product.value && product.value.variations[0]?.variationAttributes) || []
  )
  const color = masterVariantAttributes.value.find((attr) => attr.name === AgnosticBaseFacetAttrs.color)?.value

  const hasSizes = masterVariantAttributes.value.some((attr) => attr.name === AgnosticBaseFacetAttrs.size)

  let defaultSize = ''
  if (hasSizes) {
    const sizeVariants = getSelectedVariants(product.value, {
      color: color || '',
      size: ''
    })
    const inStockVariants = sizeVariants.filter((v) => v.availability.inStock)

    if (inStockVariants.length === 1) {
      defaultSize =
        inStockVariants[0]?.variationAttributes.find((attr) => attr.name === AgnosticBaseFacetAttrs.size)?.value || ''
    }
  }

  const selectedVariationAttributes = ref<VariantFilters>({
    color: color || '',
    size: defaultSize || ''
  })

  const selectVariationAttributes = (attributes: Partial<VariantFilters>, notify = true) => {
    selectedVariationAttributes.value = { color: attributes.color || '', size: attributes.size || '' }

    if (notify) {
      send('variationSelected', { message: t('notifications.variationSelected') })
    }
  }

  const variationModel = computed(() => {
    const selectedVariants = getSelectedVariants(product.value, {
      color: selectedVariationAttributes.value.color || '',
      size: selectedVariationAttributes.value.size || ''
    })
    const selectedVariant = selectedVariants.length === 1 ? selectedVariants[0] : undefined
    const promotions = selectedVariants[0]?.promotions || []
    const prices = getProductPrices(product.value, selectedVariant)
    const variationAttributes = getAllVariationAttributes(product.value, selectedVariationAttributes.value)

    return {
      product: product.value,
      selectedVariants,
      selectedVariant,
      promotions,
      selectedVariationAttributes: selectedVariationAttributes.value,
      variationAttributes,
      prices,
      // TBD
      images: product.value.images.filter((img) => {
        if (selectedVariationAttributes.value.color) {
          return img.attributes?.color === selectedVariationAttributes.value.color
        } else {
          return true
        }
      }),
      isSelectedVariantOrderable: Boolean(
        selectedVariant && selectedVariant.availability.inStock && selectedVariant.price?.regular
      ),
      inStock: selectedVariants.some((variant) => variant.availability.inStock)
    }
  })

  if (initialFilters) {
    watch([() => [initialFilters.color, initialFilters.size]], () => {
      selectVariationAttributes(initialFilters, false)
    })

    if (initialFilters.color || initialFilters.size) {
      selectVariationAttributes(initialFilters, false)
    }
  }

  if ('selectedProperties' in product.value) {
    const filters = product.value.selectedProperties.reduce(
      (prev, attr): VariantFilters => ({ ...prev, [attr.name]: attr.key }),
      {} as VariantFilters
    )
    selectVariationAttributes(filters, false)
  }

  return {
    variationModel,
    selectVariationAttributes,
    variationAttributes: computed(() => {
      const attributes: AsfVariationOptionsProps[] = []
      for (const va of Object.keys(variationModel.value.variationAttributes)) {
        if (isVariatyonType(va) && variationModel.value.variationAttributes[va]) {
          attributes.push({
            type: va,
            options: variationModel.value.variationAttributes[va]
          })
        }
      }

      return attributes
    })
  }
}

export type VariationModel = ReturnType<typeof useVariationModel>['variationModel']['value']

function getSelectedVariants(product: AgnosticProduct, filters: VariantFilters) {
  if ('variations' in product) {
    return product.variations.filter((variant) => {
      return variant.variationAttributes.every((VA) => {
        if (!filters[VA.name]) {
          return true
        }
        return filters[VA.name] === VA.value
      })
    })
  }

  return []
}

function getAllVariationAttributes(product: AgnosticProduct, filters: VariantFilters) {
  if (!('variations' in product)) {
    return {}
  }

  const variationOptions: { [key in keyof VariantFilters]: AsfVariationAttribute[] } = {}
  const notUniqueAttrs = product.variations.map((v) => v.variationAttributes).flat(1)
  const attributes = uniqWith(notUniqueAttrs, (a, b) => a.name === b.name && a.value === b.value)
  const attrNames = uniq(attributes.map((el) => el.name))

  for (const attrName of attrNames) {
    const attributeValues = attributes.filter((a) => a.name === attrName)

    if (attributeValues) {
      variationOptions[attrName] = attributeValues
        .map((attribute) => {
          const label = attribute.displayName || attribute.name
          const value = attribute.value
          const selectedVariants = getSelectedVariants(product, { ...filters, [attribute.name]: value })

          if (selectedVariants.length) {
            const disabled = !selectedVariants.filter((variant) => variant.availability.inStock).length

            return { label, value, disabled }
          }
        })
        .filter(notEmpty)
        .sort((a, b) => sortVariantsSizes(a.label, b.label))
    }
  }

  return variationOptions
}
