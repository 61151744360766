import { computed, ref } from 'vue'
import {
  AgnosticLineItem,
  AgnosticProduct,
  AgnosticVariant,
  AgnosticWishlistLineItem,
  AgnosticWishListSearchParams,
  AgnosticWishListSearchResult,
  ErrorActions,
  FirstParameter
} from 'shared/types'

const logger = Logger.getLogger({ scope: 'agnostic', method: 'useWishlist' })

function isVariant(product: AgnosticVariant | AgnosticProduct): product is AgnosticVariant {
  return typeof (product as any).sku !== 'undefined'
}

function isWishItem(
  product: AgnosticVariant | AgnosticProduct | AgnosticWishlistLineItem
): product is AgnosticWishlistLineItem {
  return typeof (product as any).itemId !== 'undefined'
}

function isVariantOrLineItem(
  product: AgnosticVariant | AgnosticProduct | AgnosticLineItem
): product is AgnosticVariant | AgnosticLineItem {
  return typeof (product as any).sku !== 'undefined'
}

export function useWishlist(id?: string) {
  const api = useEcommerce()
  const { session, updateSession } = useSession()

  const errors = {
    search: undefined,
    loadMore: undefined,
    loadPrevious: undefined,
    update: undefined,
    remove: undefined,
    add: undefined
  }
  const error = ref<ErrorActions<typeof errors>>(errors)
  const loading = ref<boolean>(false)
  const result = ref<AgnosticWishListSearchResult | undefined>()

  function isInWishlist(product: AgnosticVariant | AgnosticProduct | AgnosticWishlistLineItem | AgnosticLineItem) {
    const wishlistValue = session.value?.wishlist

    if (isVariantOrLineItem(product)) {
      return Boolean(
        wishlistValue?.lineItems.find((lineItem) => lineItem.variantId && lineItem.variantId === product.sku)
      )
    }

    const productVariants =
      'variations' in product
        ? product.variations.filter((variant) => !variant.isSiblingVariant).map((variant) => variant.sku)
        : []

    return Boolean(
      wishlistValue?.lineItems.find(
        (variant) =>
          (variant.productId && variant.productId === product.id) || productVariants.includes(variant.variantId || '')
      )
    )
  }

  async function addProduct(product: AgnosticVariant | AgnosticProduct, qty?: number) {
    logger.debug(`useWishlist/${id}/addProduct`)

    try {
      loading.value = true
      const addWishListResult = await api.addWishlistProduct(
        isVariant(product) ? product.sku : '',
        isVariant(product) ? '' : product.id,
        qty
      )

      if (addWishListResult.ok) {
        session.value.wishlist = addWishListResult.payload
        updateSession(session.value)
        error.value = { ...error.value, add: undefined }
      } else {
        error.value = { ...error.value, add: addWishListResult.error }
        result.value = undefined
      }
    } catch (err) {
      error.value = { ...error.value, add: { __general__: 'error.global.default' } }
      logger.error(`useWishlist/${id}/addProduct`, err)
    } finally {
      loading.value = false
    }
  }

  async function removeProduct(product: AgnosticVariant | AgnosticProduct | AgnosticWishlistLineItem) {
    logger.debug(`useWishlist/${id}/removeProduct`)

    try {
      loading.value = true
      const removeResult = await api.removeWishlistProduct(
        isVariant(product) ? product.sku : '',
        isVariant(product) ? '' : product.id,
        isWishItem(product) ? product.itemId : ''
      )

      if (removeResult.ok) {
        session.value.wishlist = removeResult.payload
        updateSession(session.value)
        error.value = { ...error.value, remove: undefined }
      } else {
        error.value = { ...error.value, remove: removeResult.error }
        result.value = undefined
      }
    } catch (err) {
      error.value = { ...error.value, remove: { __general__: 'error.global.default' } }
      logger.error(`useWishlist/${id}/removeProduct`, err)
    } finally {
      loading.value = false
    }
  }

  async function search(params: AgnosticWishListSearchParams) {
    logger.debug(`useWishlist/${id}/search`, params)

    try {
      loading.value = true
      const response = await api.searchWishlistItems(params)

      if (response.ok) {
        result.value = response.payload
        error.value = { ...error.value, search: undefined }
      } else {
        error.value = { ...error.value, search: response.error }
        result.value = undefined
      }
    } catch (err) {
      error.value = { ...error.value, search: { __general__: 'error.global.default' } }
      logger.error(`useWishlist/${id}/search`, err)
    } finally {
      loading.value = false
    }
  }

  const loadMore = async (params: AgnosticWishListSearchParams) => {
    logger.debug(`useWishlist/${id}/loadMore`, params)

    try {
      loading.value = true
      const response = await api.searchWishlistItems(params)

      if (response.ok) {
        result.value = {
          lineItems: [...(result.value?.lineItems ?? []), ...response.payload.lineItems],
          pagination: response.payload.pagination
        }
        error.value = { ...error.value, loadMore: undefined }
      } else {
        error.value = { ...error.value, loadMore: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, loadMore: { __general__: 'error.global.default' } }
      logger.error(`useWishlist/${id}/loadMore`, err)
    } finally {
      loading.value = false
    }
  }

  const loadPrevious = async (params: AgnosticWishListSearchParams) => {
    logger.debug(`useWishlist/${id}/loadPrevious`, params)

    try {
      loading.value = true
      const response = await api.searchWishlistItems(params)

      if (response.ok) {
        result.value = {
          lineItems: [...response.payload.lineItems, ...(result.value?.lineItems ?? [])],
          pagination: response.payload.pagination
        }
        error.value = { ...error.value, loadPrevious: undefined }
      } else {
        error.value = { ...error.value, loadPrevious: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, loadPrevious: { __general__: 'error.global.default' } }
      logger.error(`useWishlist/${id}/loadPrevious`, err)
    } finally {
      loading.value = false
    }
  }

  const update = async (params: FirstParameter<typeof api.updateWishlistProduct>) => {
    logger.debug(`useWishlist/${id}/update`, params)

    try {
      loading.value = true
      const response = await api.updateWishlistProduct(params)

      if (response.ok) {
        session.value.wishlist = response.payload.wishlist
        updateSession(session.value)

        const updatedItem = response.payload.updatedItem
        if (result.value?.lineItems) {
          const lineItems = result.value.lineItems
            .map((li) => (li.itemId === updatedItem.itemId || li.itemId === updatedItem.prevItemId ? updatedItem : li))
            .sort((a) => (a.itemId === updatedItem.itemId ? -1 : 0))

          // we need uniq itemId here
          // possible case when user updated some variant for the variant he already has
          // so these variants can be merged into one wishlist item
          const filteredLineItems = lineItems.filter(
            (li, index) => lineItems.findIndex((liInside) => liInside.itemId === li.itemId) === index
          )

          const mergedItemsCount = Number(lineItems.length > filteredLineItems.length)

          result.value = {
            ...result.value,
            lineItems: filteredLineItems,
            pagination: {
              ...result.value.pagination,
              totalItems: result.value.pagination.totalItems - mergedItemsCount
            }
          }
        }

        error.value = { ...error.value, update: undefined }
      } else {
        error.value = { ...error.value, update: response.error }
      }
    } catch (err) {
      error.value = { ...error.value, update: { __general__: 'error.global.default' } }
      logger.error(`useWishlist/${id}/update`, err)
    } finally {
      loading.value = false
    }
  }

  return {
    async toggleProduct(product: AgnosticVariant | AgnosticProduct | AgnosticWishlistLineItem, qty?: number) {
      const isIn = isInWishlist(product)

      if (isIn) {
        return removeProduct(product)
      }
      return addProduct(product, qty)
    },
    addProduct,
    removeProduct,
    wishlist: computed(() => session.value.wishlist),
    isInWishlist,
    search,
    wishlistSearchResult: computed(() => result.value),
    loadMore,
    loadPrevious,
    loading,
    update,
    error
  }
}
